import React, { useState } from "react";
import "../Styles/basicDeal.css";
import axios from "axios";
import { baseUrl } from "..";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
const BasicDeal = ({ close }) => {
  const [discount, setDiscount] = useState(10);
  const [roomTypes, setRoomTYpes] = useState("");
  const [selectedOption, setSelectedOption] = useState("dateRange");
  const [selectedDays, setSelectedDays] = useState([
    "Su",   
    "Mo",
    "Tu",
    "We",
    "Th",
    "Fr",
    "Sa",
  ]);
  const [startDate, setStartDate] = useState("2025-02-05");
  const [endDate, setEndDate] = useState("2025-05-05");

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const [loading,setLoading] = useState(false)
  const handleDayToggle = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const saveDiscount = async () => {
    try {
      setLoading(true)
      const payload = {
        discount: discount,
        startDate: startDate,
        endDate: endDate,
        applicableDays: selectedDays,
        dealType: "basic",
        roomTypes: roomTypes,
      };
      const token = localStorage.getItem("token");
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${baseUrl}/discount-rules/create-discount-rule`,
        payload,
        headers
      );
      if (res.status === 200) {
        toast.success("Discount rule saved successfully!", {
          autoClose: 1000,
        });
        close()
      }
    } catch (error) {
      toast.error("Internal server error try again later ", {
        autoClose: 1000,
      });
      console.error("Error saving discount:", error);
    }
    finally{
      setLoading(false)
    }
  };

  return (
    <div className="basic-inputs-container ">
<h2 className="dialog-heading">
        Create a <span>Basic</span> Deal
      </h2>
<div className="close-icon" onClick={close}>
  <RxCross2  size={22}/>
</div>

      <div className="promotion-details">
        <h2 className="sub-heading">Promotion Details</h2>
        <div className="blocks">
          <h3 className="sub-heading-h3 ">
            {" "}
            Who will able to see this promotion ?
          </h3>
          <div className="promotion-details-radio">
            <label>
              <input type="radio" name="visibility" defaultChecked /> Everyone
            </label>
          </div>
        </div>

        {/* hou much discount */}
        <div className="blocks">
          <h3 className="sub-heading-h3 ">
            {" "}
            How much of a discount do you want to give ?
          </h3>
          <label>select a discount amount between 1% to 99%</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
              width: "100px",
            }}
          >
            <input
              type="number"
              min="0"
              max="100"
              style={{
                width: "60px",
                outline: "none",
                textAlign: "left",
                borderRadius: "5px",
                borderColor: "gray",
              }}
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
            <span style={{ marginLeft: "5px", color: "#666" }}>%</span>
          </div>
        </div>

        {/* add room types */}
        {/* <div className="blocks">
          <h3 className="sub-heading-h3 ">
            {" "}
            Which room types do you want to add in discount ?
          </h3>
          <label>Enter room types with comma , between</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
              width: "100px",
            }}
          >
            <input
              type="text"
              min="0"
              max="100"
              style={{
                width: "200px",
                outline: "none",
                textAlign: "left",
                borderRadius: "5px",
                borderColor: "gray",
                padding: "0.3rem",
              }}
              value={roomTypes}
              onChange={(e) => setRoomTYpes(e.target.value)}
            />
          </div>
        </div> */}
      </div>

      <div className="promotion-details">
        {/* Stay Dates */}
        <h2 className="sub-heading">Stay Dates</h2>

        <div className="blocks">
          <h3 className="sub-heading-h3">
            When can guests stay using this promotion?
          </h3>
          <div className="single-radio-div">
            <input
              type="radio"
              name="stayDates"
              checked={selectedOption === "dateRange"}
              onChange={() => setSelectedOption("dateRange")}
            />
            <label>Select a date range</label>
          </div>
          <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <label>Starts:</label>

              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <label>Ends:</label>

              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Days of the week selection */}
        <div className="blocks">
          <h3 className="sub-heading-h3">
            Which day(s) of the week would you like to include in this
            promotion?
          </h3>
          <p>
            Uncheck a day of the week to remove it from this promotion’s
            calendar
          </p>
          <div style={{ display: "flex", gap: "8px" }}>
            {daysOfWeek.map((day) => (
              <label
                key={day}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <input
                  type="checkbox"
                  checked={selectedDays.includes(day)}
                  onChange={() => handleDayToggle(day)}
                />
                {day}
              </label>
            ))}
          </div>
        </div>

        {/* Summary Box */}
        <div
          className="summary-box"
          style={{
            background: "#eef6ff",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "15px",
          }}
        >
          <p>
            <b>
              {" "}
              {discount}% discount will apply to stays between the following
              dates:
            </b>
          </p>
          <p>
            {new Date(startDate).toDateString()} –{" "}
            {new Date(endDate).toDateString()}
          </p>
        </div>
      </div>

      <div className="actions">
        <button onClick={saveDiscount} className="activate-btn">
          { loading ? "saving..." : "Activate"}
        </button>
        <button onClick={close} className="cancel-btn">
          Cancel
        </button>
      </div>
</div>
  );
};

export default BasicDeal;
