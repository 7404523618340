export const convertPayments = (allPayments) => {

    const result = []
    allPayments.forEach((element ,index) => {
        result.push({
            id: index,
            startDate:element.startDate,
            endDate:element.endDate,
            noOfBeds:element.totalBeds,
            costOfBed:element.costOfBeds,
            collectionPeriod:element.thcComission,
            tax:element.totalTax,
            totalValueReceived:element.totalHostelRevenue, 
            thcDiscountCoupons:element.thcDiscount,
            collectionAtPos:element.PaymentCollectedFromCustomer,
            collectedAtCheckin:element.PaymentPendingfromCustomer,
            thcOwesHostel:element.THCOwesHostel
        });
    });
    return result
}

