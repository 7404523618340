import React from "react";
import BasicDeal from "./BasicDeal";
import LastMinuteDeal from "./LastMinuteDeal";
const DiscountRulesLayout = ({ activeDialog, setActiveDialg }) => {
  const cancelDiscountApplying = () => {
    setActiveDialg("");
  };

  return (
    <div className="deal-dialog-container">
      <div className="overlay"></div>

      {activeDialog === "basic-deal" && (
        <BasicDeal close={cancelDiscountApplying} />
      )}
      {activeDialog === "last-minute-deal" && (
        <LastMinuteDeal close={cancelDiscountApplying} />
      )}
    </div>
  );
};

export default DiscountRulesLayout;
