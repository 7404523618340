import React, { useState } from "react";
import { FaTag, FaCalendarAlt, FaPlusCircle } from "react-icons/fa"; // Import icons from react-icons
import "../Styles/discountRules.css";
import BasicDeal from "../Components/BasicDeal";
import DiscountRulesLayout from "../Components/DiscountRulesLayout";
const DiscountRules = () => {
  const [dialogBox, setDialogBox] = useState(null);

  return (
    <div className="discount-rules-container">
      <div className="deal-cards">
        {/* Basic Deal Card */}
        <div className="deal-card">
          <div className="deal-icon">
            <FaTag size={24} />
          </div>
          <h3>Basic Deal</h3>
          <p>Recommended 10% discount or more</p>
          <p>Available for any dates</p>
          <button
            className="add-promotion-btn"
            onClick={() => setDialogBox("basic-deal")}
          >
            <FaPlusCircle size={16} /> Add Promotion
          </button>
        </div>



        {/* Last-Minute Deal Card */}
        <div className="deal-card">
          <div className="deal-icon">
            <FaCalendarAlt size={24} />
          </div>
          <h3>Last-Minute Deal</h3>
          <p>Recommended 10% discount or more</p>
          <p>Fill any empty rooms you have left</p>
          <button
            className="add-promotion-btn"
            onClick={() => setDialogBox("last-minute-deal")}
          >
            <FaPlusCircle size={16} /> Add Promotion
          </button>
        </div>


        {dialogBox && (
          <DiscountRulesLayout
            activeDialog={dialogBox}
            setActiveDialg={setDialogBox}
          />
        )}

      </div>
    </div>
  );
};

export default DiscountRules;