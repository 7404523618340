import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "..";
import "../Styles/discountList.css";
import DiscountRules from "./DiscountRules.js"
import { Select, MenuItem } from "@mui/material";
import "../Styles/discountList.css";
import { TiArrowSortedDown } from "react-icons/ti";
import { toast } from "react-toastify";
const column = [
  { field: "hostelId", headerName: "hostel id", flex: 1 },
  { field: "discount", headerName: "Discount in %", flex: 1 },
  {
    field: "created_at",
    headerName: "Created At",
    flex: 1,
  },
  {
    field: "startDate",
    headerName: "Start",
    flex: 1,
  },
  { field: "endDate", headerName: "end", flex: 1 },
  { field: "daysExclusion", headerName: "Days Excluded", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    editable: (params) => params.value === "active",
    type: "singleSelect",
    valueOptions: ["active", "inactive"],
    
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 10px",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        {params.value}
      { params.value ==="active" && <TiArrowSortedDown size={22} />}
      </div>
    ),
  
    
    // Show dropdown when editing
    renderEditCell: (params) => {
      if (params.value === "inactive") {
        return <span style={{marginLeft:"22px"}}> inactive</span>;
      }
  
      return (
        <Select
          value={params.value}
          onChange={(event) =>
            params.api.setEditCellValue({
              id: params.id,
              field: "status",
              value: event.target.value,
            })
          }
          displayEmpty
          fullWidth
          sx={{}}
          IconComponent={() => <TiArrowSortedDown style={{marginRight:"20px" ,fontSize:"50px"}} />}
        >
          <MenuItem value="inactive">inactive</MenuItem>
        </Select>
      );
    },
  }
  

,  

  { field: "dealType", headerName: "Deal", flex: 1 },
  { field: "inventoryMoreThan", headerName: "Inventory More than", flex: 1 },
  { field: "daysStart", headerName: "Start days", flex: 1 },
];
const DiscountList = () => {
  const [discountList, setDiscountList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const fetchDiscounts = async () => {
      const res = await axios.get(`${baseUrl}/discount-rules`, headers);
      console.log("discount list",res?.data)
      if (res.status === 200) {
        const data = res.data.map((i) => {
        const excludedDays = i?.daysExclusion ? JSON.parse(i.daysExclusion) : [];
          return {
            ...i,
            created_at: i.created_at.split("T")[0],
            daysExclusion: excludedDays?.join(","),
          };
        });
        setDiscountList(data);
        setFilteredData(data);
      }
    };
    fetchDiscounts();
  }, []);

  const filterDataFunction = (e) => {
    if (e.target.value === "all") { 
      setFilteredData(discountList);
    } else {
      const filterItems = discountList.filter(
        (item) => item.status === e.target.value
      );
      setFilteredData(filterItems);
    }
  };

  const handleStatusChange = async (updatedRow) => { 
    console.log(updatedRow.status)
    try {      
   const token = localStorage.getItem("token");
   const headers = { 
     headers: { Authorization: `Bearer ${token}` },  
   };
   const res = await axios.put(`${baseUrl}/discount-rules/change-status/${updatedRow.id}`,{status:updatedRow.status},headers)
   console.log(res)
   if(res.status === 200){
    toast.success(`Discount rule is now ${updatedRow.status} `,{
     autoClose:1000
    })
   }
 } catch (error) { 
   console.error("Error updating status:", error);
 } 
}; 
  return (
    <div className="m-5  main-container">
      <div className="filter-select">
        <select
          onChange={(e) => {
            filterDataFunction(e);
          }}
        >
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <DataGrid
  rows={filteredData}
  columns={column}
  rowsPerPageOptions={[1, 2, 4, 100]}
  pagination
  autoHeight
  processRowUpdate={handleStatusChange}
  sx={{
    borderRadius: 0, // Removes rounded corners
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: 0, // Removes rounded corners for header
    },
    "& .MuiDataGrid-footerContainer": {
      borderRadius: 0,
      display: "flex",
      justifyContent: "space-between", // Proper spacing for footer
      alignItems: "center",
      padding: "8px 16px",
      minHeight: "40px",
    },
    "& .MuiTablePagination-root": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    "& .MuiTablePagination-selectLabel": {
      display: "flex",
      alignItems: "center",
      marginRight: "8px", // Adds spacing between "Rows per page" and the select
      marginTop: "14px",
    },
    "& .MuiSelect-select": {
      paddingRight: "32px !important", // Space for dropdown arrow
      paddingLeft: "8px", // Adds spacing between number and arrow
      height: "32px !important",
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .MuiSelect-icon": {
      right: "8px", // Positions the arrow properly
      top: "50%", // Centers it vertically
      transform: "translateY(-50%)",
      marginLeft: "4px", // Space between number and arrow
    },
    "& .MuiTablePagination-displayedRows": {
      marginTop: "14px", // Moves "1-2 of 2" slightly downward
    },
    "& .MuiTablePagination-actions": {
      display: "flex",
      alignItems: "center",
    },
  }}
/>
<DiscountRules />
    </div>
  );
};

export default DiscountList;
