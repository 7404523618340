import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { InputGroup, InputGroupText, Input, Button } from "reactstrap";
import axios from "axios";
import { baseUrl } from "..";

const column = [
    {field:'customerEmail',headerName:'Email',flex:1},
    {field:'phoneNumber',headerName:'Phone No.',flex:1},
    {field:'PaymentCollectedFromCustomer',headerName:'Paid at Booking point',flex:1},
    {field:'PaymentPendingFromCustomer',headerName:'To be Collected',flex:1},
    { field: 'bookingDate', headerName: 'Booking Date', flex: 1 },
    { field: 'booking_id', headerName: 'Booking Id', flex: 1 },
    { field: 'roomType', headerName: 'Room Type', flex: 1 },
    { field: 'customerName', headerName: 'Customer', flex: 1 },
    { field: 'checkInDate', headerName: 'CheckIn Date', flex: 1 },
    { field: 'checkOutDate', headerName: 'CheckOut Date', flex: 1 },
    { field: 'totalRooms', headerName: 'No. of Rooms', flex: 1 },
    { field: 'cost', headerName: 'Cost', flex: 1 },
    { field: 'id', headerName: 'Action', flex: 1 },
]

class Reservation extends React.Component {
    constructor() {
        super()
        this.state = {
            pageSize: 10,
            currentPage: 0,
            searchInput: "",
            allTableData: [],
            filteredData: [],
            firstDate: "",
            secondDate: "",
            filterType:""
        }
    }

    componentDidMount() {
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }
        axios.get(`${baseUrl}/reservations/`, headers).then(response => {
            this.setState({ allTableData: response.data, filteredData: response.data })
        }).catch(err => {
            console.log(err.message)
        })
    }

    handleFilter = () => {
        const { allTableData, firstDate, secondDate, searchInput, filterType } = this.state;
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const next7Days = new Date(today);
        next7Days.setDate(today.getDate() + 7);

        const filteredData = allTableData.filter(eachData => {
            const bookingDate = new Date(eachData.bookingDate);
            const checkInDate = new Date(eachData.checkInDate);
            const newfirstDate = firstDate ? new Date(firstDate) : null;
            const newsecondDate = secondDate ? new Date(secondDate) : null;
    
            const dateCondition =
                (!newfirstDate || bookingDate >= newfirstDate) &&
                (!newsecondDate || bookingDate <= newsecondDate);
    
            const searchCondition = searchInput
                ? eachData.booking_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                  eachData.roomType.toLowerCase().includes(searchInput.toLowerCase()) ||
                  eachData.customerName.toLowerCase().includes(searchInput.toLowerCase())
                : true;
            
            let filterCondition = true;
            if (filterType === 'today') {
                filterCondition = checkInDate.toDateString() === today.toDateString();
            } else if (filterType === 'tomorrow') {
                filterCondition = checkInDate.toDateString() === tomorrow.toDateString();
            } else if (filterType === 'yesterday') {
                filterCondition = checkInDate.toDateString() === yesterday.toDateString();
            } else if (filterType === 'next7Days') {
                filterCondition = checkInDate > today && checkInDate <= next7Days;
            }
    
            return dateCondition && searchCondition && filterCondition;
        });
        this.setState({ filteredData });
    };

    setFilterType = (type) => {
        this.setState({ filterType: type }, this.handleFilter);
    };
    
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            if (name === "pageSize") {
                this.setState({ currentPage: 0 })
            }
            this.setState({ [name]: value }, () => {
                    this.handleFilter()                
            })
        }

        const {filterType} = this.state;

        return (
            <div className="m-5">
                <div className="d-flex justify-content-between mb-3">
                <div className="d-flex gap-2">
                    <Button style={{color: "rgba(255, 255, 255, 1)", backgroundColor: filterType === 'today' ? '#FD590C' : '#ff7933'}} onClick={() => this.setFilterType('today')}>Today</Button>
                    <Button style={{color: "rgba(255, 255, 255, 1)", backgroundColor: filterType === 'tomorrow' ? '#FD590C' : '#ff7933'}} onClick={() => this.setFilterType('tomorrow')}>Tomorrow</Button>
                    <Button style={{color: "rgba(255, 255, 255, 1)", backgroundColor: filterType === 'yesterday' ? '#FD590C' : '#ff7933'}} onClick={() => this.setFilterType('yesterday')}>Yesterday</Button>
                    <Button style={{color: "rgba(255, 255, 255, 1)", backgroundColor: filterType === 'next7Days' ? '#FD590C' : '#ff7933'}} onClick={() => this.setFilterType('next7Days')}>Next 7 Days</Button>
                </div>
                    <InputGroup className="border rounded w-25">
                        <InputGroupText className="bg-transparent border-0">
                            <i class="bi bi-search"></i>
                        </InputGroupText>
                        <Input className="border-0" placeholder="Search..." onChange={onChange} value={this.state.searchInput} name="searchInput" />
                    </InputGroup>
                    {/* <div className="d-flex gap-3 align-items-center">
                        <Label>Rows per page</Label>
                        <Select
                            value={this.state.pageSize}
                            onChange={onChange}
                            name="pageSize"
                            label="Rows per page"
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                    </div> */}
                    {/* <Button className="bg-blue-o text-dark-o border-0" color="dark">
                        Download
                    </Button> */}
                    <div className="d-flex gap-3 align-items-center">
                    <Input
                            type="date"
                            name="firstDate"
                            value={this.state.firstDate}
                            onChange={onChange}
                            className="form-control"
                        />
                        <Input
                            type="date"
                            name="secondDate"
                            value={this.state.secondDate}
                            onChange={onChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <DataGrid
                    rows={this.state.filteredData}
                    columns={column}
                    pageSize={this.state.pageSize}
                    rowsPerPageOptions={[1, 2, 4]}
                    pagination
                    autoHeight
                    onPageChange={(params) => this.setState({ currentPage: params.page })}
                    onPageSizeChange={(params) => this.setState({ pageSize: params.pageSize })}
                    sx={{
                        borderRadius: 0, // Removes rounded corners
                        "& .MuiDataGrid-columnHeaders": {
                          borderRadius: 0, // Removes rounded corners for header
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderRadius: 0,
                          display: "flex",
                          justifyContent: "space-between", // Proper spacing for footer
                          alignItems: "center",
                          padding: "8px 16px",
                          minHeight: "40px",
                        },
                        "& .MuiTablePagination-root": {
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        },
                        "& .MuiTablePagination-toolbar": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        },
                        "& .MuiTablePagination-selectLabel": {
                          display: "flex",
                          alignItems: "center",
                          marginRight: "8px", // Adds spacing between "Rows per page" and the select
                          marginTop: "14px",
                        },
                        "& .MuiSelect-select": {
                          paddingRight: "32px !important", // Space for dropdown arrow
                          paddingLeft: "8px", // Adds spacing between number and arrow
                          height: "32px !important",
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "normal",
                          textAlign: "center",
                        },
                        "& .MuiSelect-icon": {
                          right: "8px", // Positions the arrow properly
                          top: "50%", // Centers it vertically
                          transform: "translateY(-50%)",
                          marginLeft: "4px", // Space between number and arrow
                        },
                        "& .MuiTablePagination-displayedRows": {
                          marginTop: "14px", // Moves "1-2 of 2" slightly downward
                        },
                        "& .MuiTablePagination-actions": {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                />
            </div>
        )
    }
}

export default Reservation