import html2pdf from 'html2pdf.js';

export const generateHTMLToPDF = (reservations,startDate,endDate) => {
  // removing some unwanted columns from reservations 
  const formattedReservations = reservations.map((reservation) => {
    const formattedReservation = { ...reservation };
    delete formattedReservation.created_at;
    delete formattedReservation.hostel_id;
    delete formattedReservation.payment_id;
    delete formattedReservation.cost;
    delete formattedReservation.createdBy;
    delete formattedReservation.originalPrice;
    if (formattedReservation.bookingDate) {
      formattedReservation.bookingDate = formattedReservation.bookingDate.split('T')[0];
    }
    formattedReservation.amountToBePaidByThc = formattedReservation.PaymentCollectedFromCustomer + formattedReservation.thcCouponAmt + formattedReservation.thcDiscount - 
    formattedReservation.comissionAmt
    return formattedReservation;
  });

  // Dynamically get the keys for the table headers
  const keys = Object.keys(formattedReservations[0]);

  const content = `
  <div style="width: 100%;">
    <h2 style="text-align: center; margin-bottom: 10px;">
      Reservation Table 
      <span style="font-size: 18px; font-weight: normal; color: black;">${startDate} - ${endDate}</span>
    </h2>
    <table border="1" style="width: 100%; border-collapse: collapse; font-size: 8px; table-layout: fixed;">
      <thead>
        <tr>
          ${keys
            .map(
              (key) =>
                `<th style="padding: 1px; border: 1px solid black; background: #f0f0f0; color: black; text-align: center; word-wrap: break-word;">${key}</th>`
            )
            .join('')}
        </tr>
      </thead>
      <tbody>
        ${formattedReservations
          .map(
            (reservation) =>
              `<tr>
                ${keys
                  .map(
                    (key) =>
                      `<td style="padding: 1px; border: 1px solid black; text-align: center; word-wrap: break-word; max-width: 80px; overflow: hidden;">${reservation[key]}</td>`
                  )
                  .join('')}
              </tr>`
          )
          .join('')}
      </tbody>
    </table>
  </div>
`;
  // Create a temporary element to hold the content
  const element = document.createElement('div');
  element.innerHTML = content;

  // Generate the PDF
  // const options = {
  //   margin: 10,
  //   filename: `reservations_${startDate}__${endDate}.pdf`,
  //   html2canvas: { scale: 2 },
  //   jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }, // Landscape mode for better fit
  // };
  const options = {
    margin: 5,
    filename: `reservations_${startDate}__${endDate}.pdf`,
    html2canvas: { scale: 1.5, useCORS: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, 
  };
  
  html2pdf().set(options).from(element).save();
};
